<template>
    <client-page>
        <section class="section">
            <v-container>
                <div class="section-title-wrap" data-aos="fade-left">
                    <h2 class="client-title--xxxl">
                        <template v-if="$route.query.code == 'notice'">공지사항</template>
                        <template v-if="$route.query.code == 'report'">언론보도</template>
                        <template v-if="$route.query.code == 'newsletter'">위드캔 스토리</template>
                        <template v-if="$route.query.code == 'library'">발간자료실</template>
                    </h2>
                </div>

                <!-- 발간자료실 상단 -->
                <div v-if="$route.query.code == 'library'" class="library-report">
                    <div class="library-report__inner">
                        <v-row align="center">
                            <v-spacer />
                            <v-col cols="5" lg="4">
                                <v-img src="/images/test-img.jpg" :aspect-ratio="1" class="border-radius-16" />
                            </v-col>
                            <v-col cols="7" lg="6">
                                <v-row class="row--sm white--text">
                                    <v-col cols="12">
                                        <h3 class="client-title--lg">자료 제목이 옵니다.</h3>
                                    </v-col>
                                    <v-col cols="12">
                                        <p class="client-title--sm font-weight-regular">자료 설명이 옵니다.</p>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-sheet color="transparent" max-width="416" class="d-flex j">
                                            <v-row justify="spaceBetween" class="row--sm">
                                                <v-col cols="12" md="6">
                                                    <v-btn block rounded="pill" large>
                                                        <span class="primary--text">
                                                            온라인 연차보고
                                                            <v-icon small class="ml-10">mdi-chevron-right</v-icon>
                                                        </span>
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-btn block outlined color="white" rounded="pill" large>
                                                        PDF 보기
                                                        <v-icon small class="ml-10">mdi-chevron-right</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </div>
                <!-- <tab-fair v-if="$route.query.code === 'fair' && !$route.params._board && $route.query.mode !== 'input'" /> -->

                <template v-if="!$route.params._board && $route.query.mode !== 'input'">
                    <!-- 소식 -->
                    <template v-if="$route.query.code == 'notice'">
                        <v-tabs v-model="tab" grow hide-slider :show-arrows="false" class="board-tabs">
                            <v-tab>전체</v-tab>
                            <v-tab>일반공지</v-tab>
                            <v-tab>인재채용</v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="tab">
                            <!-- 전체 -->
                            <v-tab-item>
                                <board-list />
                            </v-tab-item>
                            <!-- 일반공지 -->
                            <v-tab-item>
                                <board-list />
                            </v-tab-item>
                            <!-- 인재채용 -->
                            <v-tab-item>
                                <board-list />
                            </v-tab-item>
                        </v-tabs-items>
                    </template>

                    <!-- 위드캔 스토리 -->
                    <template v-else-if="$route.query.code == 'newsletter'">
                        <v-tabs v-model="tab" grow hide-slider :show-arrows="false" class="board-tabs">
                            <v-tab>전체</v-tab>
                            <v-tab>재단 소식</v-tab>
                            <v-tab>후원자 소식</v-tab>
                            <v-tab>사업기관 소식</v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="tab">
                            <!-- 전체 -->
                            <v-tab-item>
                                <board-list :limit="6" />
                            </v-tab-item>
                            <!-- 재단 소식 -->
                            <v-tab-item>
                                <board-list :limit="6" />
                            </v-tab-item>
                            <!-- 후원자 소식 -->
                            <v-tab-item>
                                <board-list :limit="6" />
                            </v-tab-item>
                            <!-- 사업기관 소식 -->
                            <v-tab-item>
                                <news-list :limit="6" />
                            </v-tab-item>
                        </v-tabs-items>
                    </template>

                    <template v-else>
                        <board-list />
                    </template>
                </template>
                <board-write v-else-if="$route.query.mode === 'input'" />
                <board-view v-else />
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisualBoard from "@/components/client/sub/sub-visual-borad.vue";
// import TabFair from "@/components/client/sub/tab-fair.vue";
import BoardView from "@/components/client/support/board-view.vue";
import BoardList from "@/components/client/support/board-list.vue";
import BoardWrite from "@/components/client/support/board-write.vue";

import NewsList from "@/components/client/support/news-list.vue";

export default {
    components: {
        ClientPage,
        SubVisualBoard,
        // TabFair,
        BoardView,
        BoardList,
        BoardWrite,
        NewsList,
    },
    data() {
        return {
            tab: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {},
    },
    watch: {
        code() {},
    },
};
</script>

<style lang="scss" scoped>
.board-tabs {
    margin-bottom: 40px;
    ::v-deep(.v-slide-group__prev) {
        display: none;
    }
    .v-tab {
        background-color: #f5f5f5;
        &:first-of-type {
            border-top-left-radius: 999px;
            border-bottom-left-radius: 999px;
        }
        &:last-of-type {
            border-top-right-radius: 999px;
            border-bottom-right-radius: 999px;
        }
        &--active {
            background-color: var(--v-primary-base);
            color: #fff;
        }
    }
}
.library-report {
    padding: 40px 0;
    margin-bottom: 40px;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100vw;
        max-width: 1920px;
        height: 100%;
        background: rgb(100, 148, 67) url(/images/news/library.jpg) no-repeat center / cover;
    }
    &__inner {
        position: inherit;
        z-index: 1;
    }
}
@media (min-width: 768px) {
    .board-tabs {
        margin-bottom: 60px;
        ::v-deep(.v-tabs-bar),
        .v-tab {
            height: 60px;
        }
    }
    .library-report {
        padding: 86px 0;
        margin-bottom: 80px;
    }
}
</style>