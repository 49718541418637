<template>
    <div>
        <v-row>
            <v-col v-for="board, idx in boards" :key="idx" cols="6" md="4">
                <div class="link-item_btn-wrap mb-4 mb-lg-16">
                    <v-img :aspect-ratio="1" :src="board?.thumb" class="w-100 mb-8 mb-lg-0 border-radius-16"/>
                    <v-btn :to="board?.files[0]?.url" target="_blank" outlined tile class="view-btn w-100 mw-lg-120px px-8 px-lg-16">
                        <span class="d-inline-block text-center" style="width: calc(100% - 18px);">보러가기</span>
                        <i class="icon icon-right-arrow-black ml-2 ml-lg-4"></i>
                    </v-btn>
                </div>
                <v-btn outlined tile block height="auto" color="primary" class="mw-100 download-btn" @click="download(`${board?.files[0]?.url}`, `${board?.subject}`)">
                    <span class="download-btn__tit">
                        {{board?.subject}}
                    </span>
                    <i class="icon icon-right-arrow-primary ml-2 ml-lg-4"></i>
                </v-btn>
                <v-divider />
                <div class="mt-4">
                    <v-row>
                        <v-col>
                            <v-btn block v-if="board?.writer?._id === user?._id" @click="$router.push(`/community/news/${board._id}?mode=input`)" outlined color="grey-9">수정하기</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn block v-if="board?.writer?._id === user?._id" @click="deleteBoard(board)" outlined color="grey-9">삭제하기</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        <!-- <div>
            <v-row>
                <v-col v-for="board, idx in boards" :key="idx">
                    <div @click="download(`${board.files[0].url}`, `${board.subject}`)">
                        <div>
                            <div class="w-100 pa-20 pa-md-30">
                                <v-img :src="board.thumb" width="400px"/>
                                <v-btn>{{board.subject}} PDF 다운로드 ></v-btn>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div> -->
        <div class="v-pagination-wrap">     
            <v-pagination v-model="page" :length="pageCount" @input="search()"/>
        </div>
        <div v-if="!!write" class="mt-20 mt-lg-40">
            <v-row>
                <v-spacer />
                <v-col cols="auto">
                    <v-btn outlined color="grey" @click="$router.push(`?mode=input`)">
                        글쓰기
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import download from "downloadjs";


export default {
    components:{
    },
    data() {
        return {
            filter:{
                code: "news",
                searchKey: this.$route.query.searchKey || "",
                searchValue: this.$route.query.searchValue || "",
            },
            filterItems: [ "제목+내용", "제목", "내용" ],

            boards: [],
            page: 1,
            pageCount: 0,
			limit: 8,
            tab: null,

            write: false,
            user: null
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        async init(){
            if(this.$store.state.accessToken){
                let { user } = await api.v1.me.get();
                this.user = user;
                user.isApproved === true ? this.write = true : this.write = false;
            }
            let { summary, boards } = await api.v1.boards.gets({ 
                    headers: { 
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: { code: "news" }
                });

            this.pageCount = Math.ceil(summary.totalCount / this.limit);   
            this.boards = boards;
        },
        async search(){
            let { summary, boards } = await api.v1.boards.gets({ 
                    headers: { 
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter
                });

            this.pageCount = Math.ceil(summary.totalCount / this.limit);   
            this.boards = boards;
        },
        download(path, rename){
            api.getResource(path).then(file => download(file, rename));
        },
        async deleteBoard(board){
            if(confirm("삭제하시겠습니까?")) {
                await api.v1.boards.delete(board);
                this.init()
            }
        },
        async editBoard(board){
            if(confirm("삭제하시겠습니까?")) {
                await api.v1.boards.delete(board);
                this.init()
            }
        },
    },
    watch: {
        "$route.query.code"(){
            this.init()
        }
    }

}
</script>

<style lang="scss" scoped>
.download-btn{
    padding: 4px 8px !important;
    white-space: unset;
    ::v-deep .v-btn__content{
        width: 100%;
        max-width: 100%;
        height: 48px;
        >.download-btn__tit{
            display: inline-block;
            max-height: 100%;
            width: calc(100% - 18px);   
            white-space: normal;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;         
        }
    }
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .download-btn{
        padding: 8px 16px !important;
        ::v-deep .v-btn__content{
            height: 57px;
            >.download-btn__tit{
                width: calc(100% - 20px);        
            }
        }
    }

    .link-item_btn-wrap{
        position: relative;
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.4);
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            transition: 0.25s ease-out;
            border-radius: 16px;
        }
        .v-btn.view-btn{
            position: absolute;
            top: calc(50% + 20px);
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            opacity: 0;
            visibility: hidden;
            transition: 0.25s ease-out;
            border-color: #fff;
            color: #fff;
            .icon-right-arrow-black{
                background-image: url(/images/icon/icon-right-arrow.svg);
            }
        }
    }
    .link-item_btn-wrap:hover{
        &::after{
            opacity: 1;
            visibility: visible;
        }
        .v-btn{
            top: 50%;
            opacity: 1;
            visibility: visible;
        }
    }
    .v-btn.download-btn{
        height: 56px;
    }
}
@media (min-width:1200px){
}

</style>