var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('div', {
    staticClass: "section-title-wrap",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('h2', {
    staticClass: "client-title--xxxl"
  }, [_vm.$route.query.code == 'notice' ? [_vm._v("공지사항")] : _vm._e(), _vm.$route.query.code == 'report' ? [_vm._v("언론보도")] : _vm._e(), _vm.$route.query.code == 'newsletter' ? [_vm._v("위드캔 스토리")] : _vm._e(), _vm.$route.query.code == 'library' ? [_vm._v("발간자료실")] : _vm._e()], 2)]), _vm.$route.query.code == 'library' ? _c('div', {
    staticClass: "library-report"
  }, [_c('div', {
    staticClass: "library-report__inner"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "5",
      "lg": "4"
    }
  }, [_c('v-img', {
    staticClass: "border-radius-16",
    attrs: {
      "src": "/images/test-img.jpg",
      "aspect-ratio": 1
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "7",
      "lg": "6"
    }
  }, [_c('v-row', {
    staticClass: "row--sm white--text"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "client-title--lg"
  }, [_vm._v("자료 제목이 옵니다.")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "client-title--sm font-weight-regular"
  }, [_vm._v("자료 설명이 옵니다.")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex j",
    attrs: {
      "color": "transparent",
      "max-width": "416"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "spaceBetween"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "rounded": "pill",
      "large": ""
    }
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v(" 온라인 연차보고 "), _c('v-icon', {
    staticClass: "ml-10",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-chevron-right")])], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "outlined": "",
      "color": "white",
      "rounded": "pill",
      "large": ""
    }
  }, [_vm._v(" PDF 보기 "), _c('v-icon', {
    staticClass: "ml-10",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-chevron-right")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)]) : _vm._e(), !_vm.$route.params._board && _vm.$route.query.mode !== 'input' ? [_vm.$route.query.code == 'notice' ? [_c('v-tabs', {
    staticClass: "board-tabs",
    attrs: {
      "grow": "",
      "hide-slider": "",
      "show-arrows": false
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v("전체")]), _c('v-tab', [_vm._v("일반공지")]), _c('v-tab', [_vm._v("인재채용")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('board-list')], 1), _c('v-tab-item', [_c('board-list')], 1), _c('v-tab-item', [_c('board-list')], 1)], 1)] : _vm.$route.query.code == 'newsletter' ? [_c('v-tabs', {
    staticClass: "board-tabs",
    attrs: {
      "grow": "",
      "hide-slider": "",
      "show-arrows": false
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v("전체")]), _c('v-tab', [_vm._v("재단 소식")]), _c('v-tab', [_vm._v("후원자 소식")]), _c('v-tab', [_vm._v("사업기관 소식")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('board-list', {
    attrs: {
      "limit": 6
    }
  })], 1), _c('v-tab-item', [_c('board-list', {
    attrs: {
      "limit": 6
    }
  })], 1), _c('v-tab-item', [_c('board-list', {
    attrs: {
      "limit": 6
    }
  })], 1), _c('v-tab-item', [_c('news-list', {
    attrs: {
      "limit": 6
    }
  })], 1)], 1)] : [_c('board-list')]] : _vm.$route.query.mode === 'input' ? _c('board-write') : _c('board-view')], 2)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }